<template>
  <v-app-bar
    id="default-app-bar"
    elevate-on-scroll
    app
    dark
    class="v-bar--underline"
    color="gray"
    :clipped-left="!$vuetify.rtl"
    :clipped-right="$vuetify.rtl"
    height="70"
    flat
  >
    <!-- <v-btn
      depressed
      large
      retain-focus-on-click

      @click="$vuetify.goTo(0)"
    > -->
    <strong
      class="mr-1 font-weight-black"
    >Housing</strong>
    <button
      class="primary--text"
      @click="navigateToHome"
    >
      Eden
    </button>
    <pre>&nbsp;&nbsp;&nbsp;</pre>
    <!-- </v-btn> -->
    <v-spacer />
    <!-- {{ $t("message.language") }}: -->
    <!-- <v-chip
      @click="handleClick_changeLanguage('en')"
    >
      En
    </v-chip>
    <v-chip
      @click="handleClick_changeLanguage('ja')"
    >
      Ja
    </v-chip>
    <v-chip
      @click="handleClick_changeLanguage('ko')"
    >
      Ko
    </v-chip> -->

    <!-- '' -->
    <div>
      <v-select
        v-model="select"
        hide-details
        :items="country"
        :label="$t('message.language')"
        solo
        prepend-inner-icon="mdi-web"
        @input="handleClick_changeLanguage($event)"
      />
    </div>

    <!-- <v-btn
      icon
      dark
      color="#E91E63"
      @click="scrollTop"
    >
      <v-icon x-large>
        mdi-chevron-up-circle
      </v-icon>
    </v-btn> -->
    <!-- <v-toolbar-title
      class="font-weight-light text-h5"
      v-text="name"
    /> -->

    <v-app-bar-nav-icon
      class="hidden-md-and-up"
      @click="drawer = !drawer"
    />

    <default-drawer-toggle class="hidden-sm-and-down" />

    <!-- v-text="name" -->

    <!-- <v-spacer /> -->

    <!-- <default-search class="hidden-sm-and-down" /> -->

    <!-- <default-go-home /> -->

    <!-- <default-notifications /> -->

    <!-- <default-account /> -->
  </v-app-bar>
</template>

<script>
  // Utilities
  import { get, sync } from 'vuex-pathify'

  export default {
    name: 'DefaultBar',

    components: {
      // DefaultAccount: () => import(
      //   /* webpackChunkName: "default-account" */
      //   './widgets/Account'
      // ),
      DefaultDrawerToggle: () => import(
        /* webpackChunkName: "default-drawer-toggle" */
        './widgets/DrawerToggle'
      ),
      // DefaultGoHome: () => import(
      //   /* webpackChunkName: "default-go-home" */
      //   './widgets/GoHome'
      // ),
      // DefaultNotifications: () => import(
      //   /* webpackChunkName: "default-notifications" */
      //   './widgets/Notifications'
      // ),
      // DefaultSearch: () => import(
      //   /* webpackChunkName: "default-search" */
      //   './widgets/Search'
      // ),
    },
    data: function () {
      return {
        country: ['en', 'ja', 'ko', 'zh-CHS', 'zh-CHT'],
        select: this.$i18n.locale,
      }
    },

    computed: {
      ...sync('app', [
        'drawer',
        'mini',
      ]),
      name: get('route/name'),
    },
    created: function () {
      // console.log(this.$i18n.locale)
      if (this.$i18n.locale === 'zh') {
        this.select = 'zh-CHT'
        this.handleClick_changeLanguage(this.select)
      }
    },
    methods: {
      navigateToHome () {
        this.$router.push('/').catch(err => {
          // 同じページに対するエラーを無視する
          if (err.name !== 'NavigationDuplicated') {
            throw err
          }
        })
        // ここでページをリフレッシュまたはデータをリセットするロジックを追加する
      },
      reloadAndScrollTop () {
        // ページをリロードします（もし必要なら）
        location.reload()
        // ページのトップにスクロールします
        this.$vuetify.goTo(0)
      },
      // scrollTop: function () {
      //   window.scrollTo({
      //     top: 0,
      //     behavior: 'smooth',
      //   })
      // },
      handleClick_changeLanguage (event) {
        if (event === 'zh-CHS') { this.$i18n.locale = 'zh-CN' } else if (event === 'zh-CHT') { this.$i18n.locale = 'zh-TW' } else { this.$i18n.locale = event }
      },
      reload () {
        window.location.reload(true)
        console.log('reload')
      },
    },
  }
</script>
